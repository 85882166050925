.w-100 {
  width: 100%;
}

.no-wrap {
  flex-wrap: nowrap;
}

.text-center {
  text-align: center;
}

.h-100 {
  height: 100%;
}

.mb-1 {
  margin-bottom: 1rem;
}
